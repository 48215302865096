import React, { useState, useEffect, useRef } from "react";
import { t } from "ttag";
import { toast } from "react-toastify";
import { FaQuestionCircle } from "react-icons/fa";

import { getUsrThumbUrl, fetchPost, loading, boxShadow, parseJson, isMobile } from "../util";
import { NumberInput, Modal } from "./shared";
import { DesignData, getModelNameGlassOnly, ProjectData, SpecsMap } from "../types";
import { parseRoomNumbers } from '../parser';


type OrderProps = {
  buttonId: string,
  project: ProjectData,
  sendOrderDialogMobile: () => void,
  sendMailList: (project: ProjectData, sendMail: boolean) => void,
  onOrderPlaced: () => void,
}

// Component props can have optional values with destructurings
// const Counter:React.FC<{ initial?: number }> = ({ initial = 0 }) => {}

const MIN_VAL = 0;
const MAX_VAL = 500;

const OrderComponent: React.FC<OrderProps> = (props) => {
  const { project, buttonId, sendOrderDialogMobile, sendMailList } = props;
  const { designs } = project;

  const [units, setUnits] = useState(Array<number>());
  const [orderCode, setOrderCode] = useState("");

  const orderFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {

    const units = designs.map(d => {
      const isVH = SpecsMap[d.model].extra === "vh";
      var orderExtraString = isNaN(parseInt(parseJson(parseJson(d.order_extra)?.numbers?.[0])));
      if(orderExtraString)
      {
        return 1;
      }

      return (isVH && d.order_extra) ? (parseRoomNumbers(parseJson(d.order_extra)?.numbers)?.[0]?.length ?? d.order_quantity ?? 1) : (d.order_quantity ?? 1);
    });

    console.log("CUB", units);

    setUnits(units);
  }, [project.id, designs]);

  useEffect(() => {
    project.newOrderCode = orderCode;
  }, [orderCode]);

  const changeUnit = (i: number, val: number, d: DesignData) =>
  {
    const newUnits = units.slice();
    newUnits[i] = val;
    var aux = parseRoomNumbers(parseJson(d.order_extra)?.numbers ?? "")?.[0]?.length ?? 0;
    console.log("unidades" + aux);
    d.order_quantity = val;
    setUnits(newUnits);

  }

  const onClick = async () => {

    if(isMobile())
    {
      document.getElementById(buttonId)!.click();
      sendOrderDialogMobile();
      return;
    } 
    const confirmed = window.confirm(
      t`Once the project is finished, it cannot be modified again and an order must be placed with the new generated codes.`
    );
  
    if (!confirmed) return;
  
    //const orderField = orderFieldRef.current;
    //if (orderField?.reportValidity()) {
      loading(true);
  
      const orders = units
        .map((quantity, i) => ({ design_id: designs[i].id, quantity }))
        .filter(o => o.quantity > 0);
  
      project.newOrderCode = orderCode; 
      console.log("orderProject.newOrderCode antes de enviar:", project.newOrderCode);
      const body = JSON.stringify({ code: project.id , orderCode: orderCode, orders });
      const res = await fetchPost(`/api/project/${project.id}/order`, body);
  
      if (res.ok) {
        toast.success(t`Order sent successfully!`);
        document.getElementById(buttonId)!.click();
        props.onOrderPlaced();
      } else {
        const json = await res.json();
        const e = json.errors;
        if (e?.code && (e.code as string[]).includes("validation.unique")) {
          toast.error(t`This order code already has an order placed`);
        } else {
          toast.error(t`Error sending order`);
        }
      }

      setTimeout(() => {
        sendMailList(project, true);
      }, 2000);
      
      
      loading(false);
  //  }
  };

  const last = (i: number) => i === designs.length - 1;

  const footer = <>
  <div className="input-group" style={{ width: "50%" }}>
      <div className="input-group-prepend">
        <div className="input-group-text" title={t`This field allow you to add comments to your order`}><FaQuestionCircle /></div>
      </div>
      
      <input type="text" required ref={orderFieldRef} name="orderNum"
        className="form-control" placeholder={t`Order code`}
        value={orderCode} onChange={e => setOrderCode(e.target.value)} />
    </div>

    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <button type="button" className="btn btn-primary"
        onClick={onClick}>{t`Finish project`}</button>
    </div>
  </>;

  return <Modal btnId={buttonId} title={t`Order of project '${project.name}'`} footer={footer}>
    {designs.map((d, i) => {
      const isVH = SpecsMap[d.model].extra === "vh" && !isNaN(parseInt(parseJson(parseJson(d.order_extra)?.numbers?.[0])));;

      return <div key={d.id} className="row" style={{ height: last(i) ? 100 : 130 }}>
        <div className="col-2 col-sm-3">
          <img src={getUsrThumbUrl(d.id, d.updated_at || "")} alt="..." decoding="async" loading="lazy"
            className="d-block m-auto" style={{ maxHeight: 80, maxWidth: "160%", boxShadow }} />
        </div>

        <div className="col" style={{ overflow: "hidden" }}>
          <h5>{d.name.slice(0, -7)}</h5>
          <small className="text-muted">{t`Model:`} {d.name.slice(-5) + getModelNameGlassOnly(d.model, d.glass_only)}</small>
          <br />
          <small className="text-muted">{t`Option:`} {SpecsMap[d.model].option} {d.glass_only ? t`(glass only)` : false}</small>
        </div>

        <div className="col-5 col-sm-4">
          {t`Units`}

          <NumberInput onUnitChange={n => changeUnit(i, n, d)} disabled={isVH} min={MIN_VAL} max={MAX_VAL} default={units[i]} />
        </div>
        { // Don't print last separator
          !last(i) && <div className="col-12"><hr /></div>}
      </div>;
    })
    }
  </Modal>;
}

export default OrderComponent;
