import React, { Component, useRef, useState } from "react";
import { Path, load as OpenTypeLoad } from 'opentype.js';

import elementToPath from "element-to-path";

import { fetchPost, fetchGet, getBgUrl, getIconUrl, getLogoUrl, getMaskUrl, loading, embedSvgImages, isMobile, boxShadowBig, fixColor, filenamify, MODE, PROD, parseJson, TEXT_SEPARATOR, parseColor, isCharRTL, isLandscapeDesign } from "../util";
import { Draggable } from "./shared";
import {DesignData, SpecsMap, TRMDScreenPos, TLDigitsPos, TLHDigitsPos, TLVDigitsPos, VHNumberPos, VHHNumberPos, Fonts, PPMM, TLRVDigitsPos, Coords2 } from "../types";
import { BrandBes, BrandIng, BrandSize } from "../resources/common/brands";

import ScreenTRMDUrl from "../resources/common/images/screen_trmd.png";
import ScreenVHDUrl from "../resources/common/images/screen_vhd.png";
import { ReactComponent as ScreenTL } from "../resources/common/images/screen_tl.svg";
import { ReactComponent as ScreenTLRVWhite } from "../resources/common/images/screen_tlrv_white.svg";
import { ReactComponent as ScreenTLRVBlack } from "../resources/common/images/screen_tlrv_black.svg";
import { ReactComponent as ScreenTLRVPrint } from "../resources/common/images/screen_tlrv_print.svg";

import { ReactComponent as ScreenTLRVWhiteNoFan } from "../resources/common/images/screen_tlrv_white_nofan.svg";
import { ReactComponent as ScreenTLRVBlackNoFan } from "../resources/common/images/screen_tlrv_black_nofan.svg";
import { ReactComponent as ScreenTLRVPrintNoFan } from "../resources/common/images/screen_tlrv_print_nofan.svg";

import { parseRoomNumbers } from '../parser';

const STAGE_ID = "svgStage";
const SVG_TEXT_ID = "svgText";

const CANVAS_ID = "svgCanvas";
const CLIP_PATH_ID = "SvgModelClipPath";
const CLIP_PATH_URL = `url(#${CLIP_PATH_ID})`;

const Brand = MODE === "bes" ? BrandBes : BrandIng;

const SVG_HOVER_ID = (i: number) => `svg_hover_${i}`;
export var tactilArea = true;

export function setTactilArea(value: boolean) {
  tactilArea = value;
}
export type Formats = "eps" | "svg" | "svg12" | "png"; // svg12 is SVG 1.2 with CMYK support

type ModelSVGProps = {
  design?: DesignData,
  registerOnResize?: (handler: () => void) => void,

  forPrinting?: boolean,

  downloadFinished?: (ret: Blob | string) => void,
  justReturn?: boolean,
  format: Formats,
  pngWidth?: number,

  downloadNoTactilArea?: boolean,

  updateDesign?: (p: DesignData) => void,
  height?: number,

  hotelRoomIndex?: number,
}

type ModelSVGState = {
  width: number,
  height: number,
}

export default class ModelSVGComponent extends Component<ModelSVGProps, ModelSVGState> {
  parentRef = React.createRef<HTMLDivElement>();

  constructor(props: ModelSVGProps) {
    super(props);

    // Default values
    this.state = { width: 400, height: 600 };

    if (props.registerOnResize) {
      props.registerOnResize(this.onResize);
    }
  }

  onResize = () => {
    const parent = this.parentRef.current;
    if (parent) {
      let w;
      if (isMobile()) {
        w = parent.offsetWidth;
      } else {
        // 300 is the minimum size of the left column
        w = Math.min(window.innerWidth - 300, parent.offsetWidth);
      }

      this.setState({ width: w, height: parent.offsetHeight });
    }
  }

  componentDidMount() {
    this.onResize();
  }

  getSvg = () => {
    const svgElem = this.parentRef.current?.querySelector("svg");
    if (svgElem) { return svgElem.outerHTML; }
  }

  hoverIcon = (i?: number) => {
    let counter = -1;
    let elem: HTMLElement | null;

    while ((elem = document.getElementById(SVG_HOVER_ID(++counter))) !== null) {
      if (counter === i) {
        elem.removeAttribute("display");
      } else {
        elem.setAttribute("display", "none");
      }
    }
  }

  SMALLEST_PNG = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII="

  renderPng = async (width: number, getBlob?: boolean) => {
    const svg = this.getSvg();
    if (!svg) {
      alert("NO SVG!");
      return this.SMALLEST_PNG;
    }

    // Load the model dimensions, and scale them down
    const { design } = this.props;
    if (!design) { return this.SMALLEST_PNG; }
    const { dimensions } = SpecsMap[design.model];

    // Scale the image to the wanted size
    const downscale_factor = width / dimensions[0];

    const [w, h] = dimensions.map(d => d * downscale_factor);

    // Prepare the canvas
    const canvas = document.getElementById(CANVAS_ID) as HTMLCanvasElement;
    canvas.width = w;
    canvas.height = h;

    let data = await embedSvgImages(svg);

    return new Promise<string>(resolve => {
      const svgBlob = new Blob([data!], { type: "image/svg+xml" });
      const url = URL.createObjectURL(svgBlob);
      const img = new Image();

      img.onload = () => {
        const ctx = canvas.getContext("2d")!;
        ctx.drawImage(img, 0, 0, w, h);
        resolve(canvas.toDataURL("image/png"));
      };
      img.src = url;
    });
  }

  downloadImg = async () => {
    loading(true);
    setTactilArea(false);
    const svg = this.getSvg();
    if (!svg) {
      alert("NO SVG!");
      return;
    }

    let blob: Blob | string;
    let filename: string;

    const { design } = this.props;
    const orderCode = (design as any).order_code;

    const name = filenamify((orderCode ? orderCode + "__" : "") + (design?.order_quantity || 0) + "uds__" + design?.name);

    if (this.props.format === "svg" || this.props.format === "svg12") {
      const data = await embedSvgImages(svg);
      blob = new Blob([data], { type: "image/svg+xml" });
      filename = `${name}.svg`;

    } else if (this.props.format === "eps") {
      const data = await embedSvgImages(svg);
      const res = await fetchPost("/api/admin/svg2eps", JSON.stringify({ svg: data }));
      blob = await res.blob();
      filename = `${name}.eps`;

    } else if (this.props.format === "png") {
      blob = await this.renderPng(this.props.pngWidth || 500, true);
      filename = `${name}.png`;

    } else {
      return;
    }

    if (!this.props.justReturn) {
      window.saveAs(blob, filename);
      loading(false);
    }

    this.props.downloadFinished!(blob);
    setTactilArea(true);
  }

  render() {
    const { design, height: propHeight, updateDesign, forPrinting, format, hotelRoomIndex, downloadNoTactilArea } = this.props;
    const height = propHeight !== undefined ? propHeight : "100%";

    if (!design) { return false; }

    const specs = SpecsMap[design.model];

    const [w, h] = specs.dimensions.map(p => p * PPMM);
    const divW = this.state.width;
    const divH = this.state.height;

    const scale = Math.min(divW / w, divH / h) * 0.8;
    const dx = (divW - w) / 2;
    const dy = (divH - h) / 2;

    const defaultLogoScale = specs.defaultLogoCoords[2];

    const style = height === 0 ? {} : {
      transform: `translate(${dx}px, ${dy}px) scale(${scale})`,
      width: w, height: h, boxShadow: boxShadowBig,
    };

    const doDownload = !!this.props.downloadFinished;
    const onReady = doDownload ? this.downloadImg : undefined;

    return (      
    
      <div ref={this.parentRef} style={{ height, overflow: "hidden" }} >
        <input type="hidden" id={SVG_TEXT_ID}></input>
        <SvgModel design={design} style={style} scale={scale} onReady={onReady} forPrinting={forPrinting || false} hotelRoomIndex={hotelRoomIndex}
        updateDesign={updateDesign} doDownload={doDownload} defaultLogoScale={defaultLogoScale} format={format} downloadNoTactilArea={downloadNoTactilArea}/>
      </div>
    );
  }
}

type SvgProps = {
  design: DesignData,
  style: React.CSSProperties,
  scale: number,
  defaultLogoScale: number,
  doDownload: boolean,
  forPrinting: boolean,
  format: Formats,
  hotelRoomIndex?: number,
  downloadNoTactilArea?: boolean,
  onReady?: () => void,
  updateDesign?: (p: DesignData) => void,
}

function SvgModel(props: SvgProps) {
  const { design, scale, onReady, updateDesign, doDownload, defaultLogoScale, forPrinting, format, hotelRoomIndex, downloadNoTactilArea } = props;
  let imageCount2 = 1;

  const DEBUG = false && !PROD;

  const incImage = (debugName: string) => {
    ++imageCount2;
    DEBUG && console.log("RENDER", design.name, imageCount2, "↑", debugName);
  };
  const decImage = (debugName: string) => {
    --imageCount2;
    DEBUG && console.log("RENDER", design.name, imageCount2, "↓", debugName);
    if (imageCount2 === 0) {
      DEBUG && console.log("RENDER", design.name, "READY!");
      onReady && setTimeout(onReady, 50);
    }
  };

  return <div id={STAGE_ID} style={{
    overflow: "hidden", zIndex: 5,
    borderRadius: 17, border: "2px solid gray", ...props.style,
  }}>
    <div style={{ opacity: 0.01, pointerEvents: "none", position: "absolute", height: 1, zIndex: 99 }}>
      <canvas id={CANVAS_ID} />
    </div>

    <SvgCanvas design={design} incImage={incImage} scale={scale} defaultLogoScale={defaultLogoScale} format={format} hotelRoomIndex={hotelRoomIndex}
    decImage={decImage} updateDesign={updateDesign} doDownload={doDownload} forPrinting={forPrinting} downloadNoTactilArea={downloadNoTactilArea}/>

  </div>;
}

type SvgCanvasProps = {
  design: DesignData,
  scale: number,
  defaultLogoScale: number,
  doDownload: boolean,
  forPrinting: boolean,
  format: Formats,
  hotelRoomIndex?: number,
  downloadNoTactilArea?: boolean,
  

  incImage: (debugName: string) => void,
  decImage: (debugName: string) => void,
  updateDesign?: (p: DesignData) => void,
}

function SvgCanvas(props: SvgCanvasProps) {
  const {design: p, incImage, decImage, updateDesign, scale, doDownload, forPrinting, defaultLogoScale, format, hotelRoomIndex, downloadNoTactilArea } = props;
  
  const updateLogoPos = (x: number, y: number) => {
    if (!updateDesign) { return };
    const scale = p.logo_coords?.[2] || defaultLogoScale;
    p.logo_coords = [x, y, scale];
    updateDesign(p);
  };

  const specs = SpecsMap[p.model];
  const [w, h] = specs.dimensions;
  const [wp, hp] = forPrinting ? [w, h].map(p => `${p}mm`) : [w, h].map(p => p * PPMM);

  const brandScale = w / BrandSize;

  if (p.bg_path) incImage("bg_path");
  if (p.logo_path) incImage("logo_path");
  p.icon_paths.filter((p, i) => p && specs.iconPos[i]).forEach((v, i) => incImage("icon_" + i));
  p.icon_texts.filter((t, i) => t && specs.iconPos[i]).forEach((v, i) => incImage("icon_text_" + i));

  const logoCoords = p.logo_coords?.length === 3
    ? p.logo_coords : specs.defaultLogoCoords;

  const logoRes = p.logo_resolution?.length === 2 ? p.logo_resolution : [1, 1] as Coords2;

  const col = (rgb: string, cmyk: string) => format === "svg12" ? `${rgb} device-cmyk(${cmyk})` : rgb;

  const white = col("#FFF", "0,0,0,0");

  // This represents the color of the layer behind the background
  // which is always white when there is an image to make opacity work
  const baseColor = p.bg_path || specs.color === "black" ? col("#000", "1,1,1,1") : white;
  const color = col("#777777", "0,0,0,0.6");

  // Note that we handle opacity as a style, so we don't handle cmyk correctly, which is fine cause it's not used
  let useBlackBrandColor = false;

  if (p.bg_path && p.bg_path.startsWith("#")) {
    const c = parseColor(p.bg_path);
    if (c) {
      const avg = (c[0] + c[1] + c[2]) / 3;
      if (avg > 127) {
        useBlackBrandColor = true;
      }

      console.log("AVG", avg);
    }

  } else if (p.bg_path) {
    if (p.brand_color === "white") useBlackBrandColor = false;
    if (p.brand_color === "black") useBlackBrandColor = true;
  } else {
    useBlackBrandColor = specs.color === "white";
  }

  if(downloadNoTactilArea == true) setTactilArea(false);
  const brandColor = useBlackBrandColor ? col("rgb(0, 0, 0)", "0,0,0,20%") : col("rgb(255, 255, 255)", "0,0,0,70%");
  const brandOpacity = 0.2;

  const isTRMD = specs.extra === "trmd";
  const isVHD = specs.extra === "vhd";
  const hasScreen = isTRMD || isVHD;
  const isVH = specs.extra === "vh";
  //hotel horizontal
  const isVHH = specs.extra === "vh" && specs.name === "Cubik-HH";
  const tlPos = specs.extra === "tl" ? (specs.name === "Cubik-TLH" ? TLHDigitsPos : TLDigitsPos) : specs.extra === "tlv" ? TLVDigitsPos : undefined;
  const tlrvPos = specs.extra === "tlrv" ? TLRVDigitsPos : undefined;

  if (isVH || isVHH) incImage("vh_text");

  const clipPath = elementToPath({
    type: 'element',
    name: 'rect',
    attributes: {
      width: w,
      height: h,
      rx: 2, // Radius 2 mm
      ry: 2, // Radius 2 mm
    },
  });

  const iconOffset = specs.centerTop ? 1 + specs.iconSize / 2 : 0;

  const orderExtraJson = parseJson(p.order_extra);
  const roomNumbers = orderExtraJson?.numbers;
  const roomNumberPrefix = orderExtraJson?.prefix ?? "";
  const roomNumberColor = orderExtraJson?.color ?? color;
  const roomNumberFont = Fonts[orderExtraJson?.font] ?? Fonts["Gotham Light"];
  const showFancoil = orderExtraJson?.showFancoil ?? MODE === "ing";

/*var orderExtraString = isNaN(parseInt(parseJson(parseJson(d.order_extra)?.numbers?.[0])));
      if(orderExtraString)
      {
        return 1;
      }

      return (isVH && d.order_extra) ? (parseRoomNumbers(parseJson(d.order_extra)?.numbers)?.[0]?.length ?? d.order_quantity ?? 1) : (d.order_quantity ?? 1);
  


*/
  const roomNumber = roomNumberPrefix + ((isVHH || isVH) ? loadRoomNumber(roomNumbers, hotelRoomIndex ?? 0, isVHH) : "");
  const isNotLandscape = !isLandscapeDesign(p);

  return <svg id="root" version={format === "svg12" ? "1.2" : "1.1"}
    xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
    x={0} y={0} width={wp} height={hp} viewBox={`0 0 ${w} ${h}`} pointerEvents="none">

    <clipPath id={CLIP_PATH_ID}> <path d={clipPath} /> </clipPath>

    <rect id="baseColor" width={w} height={h} fill={baseColor} />

    <g clipPath={CLIP_PATH_URL}>
      <>
        {/*
      {!(p.bg_path && p.bg_opacity === 100) &&
        <rect id="baseColor" width={w} height={h} fill={baseColor} />}
       */}

        {p.bg_path &&
          <Background width={w} opacity={p.bg_opacity} height={h}
            path={p.bg_path} isUser={p.bg_user} pid={p.id} done={decImage.bind(undefined, "bg_path")} p={p} />}

        {p.logo_path &&
          <Logo path={p.logo_path} coords={logoCoords} res={logoRes} scale={scale} opacity={p.logo_opacity}
            w={w} h={h} pid={p.id} updatePos={updateLogoPos} done={decImage.bind(undefined, "logo_path")} doDownload={doDownload} p={p}/>}

        {/* Apply a slight yellow, so we can use pure white as a transparent mask */}
        {/* <rect id="transparent" width={w} height={h} fill="#FFFF00" fillOpacity="0.02" /> */}

        <g id="icons">
          {specs.iconPos?.map(([x, y], i) => {
            const path = (p.icon_paths && p.icon_paths.length > 0) ? p.icon_paths?.[i] : specs.defaultIcons?.[i];
            const text = p.icon_texts?.[i];
            const iconBorder = p.icon_borders?.[i] ?? specs.defaultIconBorders?.[i];

            if (!path && !text && !iconBorder) return <React.Fragment key={i}></React.Fragment>;

            const xPos = (p.icon_hor_positions?.[i] ?? 8) - 8;
            var yPos = (p.icon_vert_positions?.[i] ?? 8) - 8;

            /*if(specs.name === "Cubik-TLH"){
              if((i === 4 || i === 1)){
                  yPos = yPos - 10;
              }
              if(i===3){
                yPos = yPos + 1.8;
              }
            }
            */

            // Icon properties
            const fixedColor = fixColor(p.icon_colors[i]);
            const iconColor = fixedColor || color;
            const iconUserSize = (p.icon_sizes?.[i] ?? 5) - 5;
            const iconSize = specs.iconSize * (1 + iconUserSize / 8);
            const squareSize = iconSize / 2 + 3;

            //  Text properties
            const textColor = p.icon_texts_colors?.[i] ?? color;
            const fontKey = p.icon_texts_fonts?.[i] ?? "Gotham";
            const userSize = (p.icon_texts_sizes?.[i] ?? 5) - 5;
            const font = Fonts?.[fontKey] ?? {};
            const textOffset = specs.centerTop ? (iconSize / 1.5 + specs.iconSize / 2.5) : (iconSize / 2 + specs.iconSize / 2.5);
            const textFinalSize = specs.iconSize * (0.25 + userSize / 80);

            // Square props
            const squareX = x + xPos - squareSize;
            const squareY = specs.centerTop ? (y + yPos - 1) : (y + yPos - squareSize);
            const squareW = squareSize * 2;
            const squareH = squareSize * 2;

            const notifyTextSize = (x: number, y: number, w: number, h: number) => {
              const elem = document.getElementById(`iconSquare${i}`);
              if (elem && elem instanceof SVGRectElement) {
                const dx = Math.max(0, (w + 4) - squareW);
                const newX = squareX - dx / 2;
                const newW = squareW + dx;
                const newH = squareH + h + 2;

                elem.setAttribute("x", newX.toString())
                elem.setAttribute("width", newW.toString())
                elem.setAttribute("height", newH.toString())
              }
            };

            return <React.Fragment key={i}>
              {/* Setting the key to math.random we make sure this rect is always repainted when there's no key */}
              {iconBorder &&
                <rect key={text ? i : Math.random().toString()} id={`iconSquare${i}`}
                  x={squareX} width={squareW} y={squareY} height={squareH} rx="3" ry="3"
                  stroke={iconColor} strokeWidth="0.5" fillOpacity="0" fill="#00000000" />}

              {path &&
                <Icon index={i} color={iconColor} path={path} size={iconSize} x={x + xPos} y={y + yPos}
                  done={decImage.bind(undefined, "icon_" + i)} centerTop={specs.centerTop} />}

              {text &&
                <Text id={i} x={x + xPos} y={y + yPos + iconOffset + textOffset} separator={TEXT_SEPARATOR}
                  text={text} done={decImage.bind(undefined, "icon_text_" + i)} size={textFinalSize} fill={textColor}
                  font={font.font} fontWeight={font.weight} url={font.url} rtl={font.rtl ?? false} notifyFinalSize={notifyTextSize} />}
            </React.Fragment>;
          })}
        </g>

        <g id="leds">
          {!specs.noLeds && specs.iconPos.map(([x, y], i) => {
            // const ledColor = p.bg_path || specs.color === "white" ? col("#4D4D4D", "0,0,0,0.7") : white;
            //return <LedCircle key={i} index={i} x={x} y={y} borderColor={ledColor}
            //  fillColor={"white"} usePoints={forPrinting && format !== "svg12"} />;

            return <LedCircle key={i} index={i} x={x} y={y} borderColor={color}
              fillColor={"none"} usePoints={false /*forPrinting && format !== "svg12"*/} isTL ={specs.extra==="tl" || specs.extra==="tlrv" || specs.extra === "tlv" || specs.extra=== "trmd"}  isHotel ={specs.extra==="vh" || specs.extra==="vhd" } isTLH ={specs.name==="Cubik-TLH"}  />;
          })}
        </g>

        {/* To make it exactly 6mm from bottom we have to add 0.095 */}
        {specs.extra != "card" && (
          <g id="brand" transform={`translate(${(!isNotLandscape || specs.name == "Cubik-TLH") ? 60 : 41.5}, ${h - 8}) scale(${brandScale})`}>
            <Brand fill={brandColor} style={{ opacity: brandOpacity }} />
          </g>
        )}

        {!doDownload && specs.iconPos.map(([x, y], i) => {
          return <HoverCircle key={i} index={i} x={x}
            y={y + iconOffset} size={specs.iconSize} />;
        })}

        {forPrinting && hasScreen &&
          <rect x={TRMDScreenPos[0]} y={TRMDScreenPos[1]}
            width={TRMDScreenPos[2]} height={TRMDScreenPos[3]}
            fill="#FFF" id="screen" />}

        {!forPrinting && hasScreen &&
          <rect x={TRMDScreenPos[0]} y={TRMDScreenPos[1]}
            width={TRMDScreenPos[2]} height={TRMDScreenPos[3]}
            fill={specs.color === "black" || p.bg_path ? "#222" : "#444"} />}

        {!forPrinting && isTRMD &&
          <image x={TRMDScreenPos[0]} y={TRMDScreenPos[1]}
            width={TRMDScreenPos[2]} height={TRMDScreenPos[3]}
            xlinkHref={ScreenTRMDUrl} />}

        {!forPrinting && isVHD &&
          <image x={TRMDScreenPos[0]} y={TRMDScreenPos[1]}
            width={TRMDScreenPos[2]} height={TRMDScreenPos[3]}
            xlinkHref={ScreenVHDUrl} />}

        {!isVHH && isVH && <Text id={999} x={VHNumberPos[0]} y={VHNumberPos[1]} size={roomNumber.length > 3 ? 30 : 35}
          fill={roomNumberColor} url={roomNumberFont.url} text={roomNumber} font={roomNumberFont.font} rtl={roomNumberFont.rtl ?? false}
          fontWeight={roomNumberFont.weight} done={decImage.bind(undefined, "vh_text")} />}
        {isVHH && <Text id={999} x={VHHNumberPos[0]} y={VHHNumberPos[1] - p.roomNumberYPos} size={getRoomFontSize(roomNumber, p)}
          fill={roomNumberColor} url={roomNumberFont.url} text={roomNumber} font={roomNumberFont.font} rtl={roomNumberFont.rtl ?? false}
          fontWeight={roomNumberFont.weight} done={decImage.bind(undefined, "vh_text")} />}
        {!forPrinting && tlPos && <ScreenTL x={tlPos[0]}
          y={tlPos[1]} width={tlPos[2]} height={tlPos[3]} />}

        {(() => {
          if (!tlrvPos) return false;
          const args = { x: tlrvPos[0], y: tlrvPos[1], width: tlrvPos[2], height: tlrvPos[3] };

          if (forPrinting) {
            if (showFancoil)
              return <ScreenTLRVPrint {...args} />;
            else
              return <ScreenTLRVPrintNoFan {...args} />;

          } else if (p.bg_path || specs.color === "black") {
            if (showFancoil)
              return <ScreenTLRVBlack {...args} />;
            else
              return <ScreenTLRVBlackNoFan {...args} />;

          } else {
            if (showFancoil)
              return <ScreenTLRVWhite {...args} />;
            else
              return <ScreenTLRVWhiteNoFan {...args} />;
          }
        })()}

        {!forPrinting && <image width="102%" height="102%" x="0%" y="-2%" opacity={1}
          xlinkHref={getMaskUrl((specs.dimensions[1] > specs.dimensions[0]) ? "v" : (specs.dimensions[1] === specs.dimensions[0] ? "sq" : "h"))} style={{ pointerEvents: "none" }} />}
        {decImage("global")}
      </>
    </g>
  </svg>
}

function getRoomFontSize(roomNumber: string, design: DesignData)
{
  var size = design.room_number_size;
  return 35 * design.room_number_size / 10;
}

function loadRoomNumber(rooms: string | undefined, index: number, isVHH: boolean) {
  if (rooms){ 
    if(isNaN(parseInt(rooms)))
    {
      return rooms;
    }
  }
  const placeholder = isVHH ? "235" : "123";
  if (!rooms) return placeholder;

  const [parsed, isError] = parseRoomNumbers(rooms)
  if (isError) return placeholder;

  if (parsed.length === 0 || index < 0 || index >= parsed.length) return placeholder;
  return parsed[index] || placeholder;
}


function Background(props: {
  path: string, isUser?: boolean, pid: string, opacity: number,
  width: number, height: number, done: () => void, p: DesignData
}) {
  const { path, isUser, pid, width, height, done, p } = props;

  const opacity = Math.max(0, Math.min(1, props.opacity / 100.0));

  if (path.startsWith("#")) {
    done();
    return <rect id="backgroundColor" width={width} height={height} fill={path} opacity={opacity} />
  } else {
    return <image id="backgroundImage" xlinkHref={getBgUrl(path, isUser, pid, p.updated_at)} x={0} y={0} width={width} height={height} opacity={opacity}
      preserveAspectRatio="xMidYMid slice" onLoad={done} onError={done} />
  }
}

function Logo(props: {
  path: string, coords: [number, number, number], res: [number, number], pid: string,
  w: number, h: number, scale: number, opacity: number, doDownload: boolean,
  updatePos: (x: number, y: number) => void, done: () => void, p: DesignData
}) {
  const url = getLogoUrl(props.path, props.pid, (props.p).updated_at);
  const [x, y, s] = props.coords;
  const opacity = Math.max(0, Math.min(1, props.opacity / 100.0));

  const size = props.w * s / 100;

  const logoFactor = props.res[0] / props.res[1];
  const sizeX = logoFactor > 1 ? size : size * logoFactor;
  const sizeY = logoFactor > 1 ? size / logoFactor : size;

  const scale = props.scale * PPMM;

  if (props.doDownload) {
    return <image id="logoImage" xlinkHref={url} preserveAspectRatio="xMidYMid" x={x} y={y} opacity={opacity}
      onLoad={props.done} onError={props.done} width={sizeX} height={sizeY} />;
  } else {
    return <Draggable initialX={x} initialY={y} scale={scale} onDragEnd={props.updatePos}
      maxX={props.w} maxY={props.h} width={sizeX} height={sizeY}>
      {
        ({ events, ...drag_props }) => <g {...events} pointerEvents="auto">
          <image id="logoImage" xlinkHref={url} preserveAspectRatio="xMidYMid" {...drag_props} opacity={opacity}
            onLoad={props.done} onError={props.done} width={sizeX} height={sizeY} style={{ cursor: "move" }} />
        </g>
      }
    </Draggable>
  }
}

function HoverCircle(props: { index: number, x: number, y: number, size: number }) {
  const { index, x, y, size } = props;

  return <circle id={SVG_HOVER_ID(index)} display="none"
    r={size * 0.6} cx={x} cy={y} fillOpacity={0}
    stroke="red" strokeOpacity={0.7} strokeWidth={size / 20} />
}

function Icon(props: {
  path?: string, color: string, centerTop?: boolean,
  x: number, y: number, size: number, done: () => void, index: number,
}) {
  const { path, color, x, y, size, centerTop, done, index } = props;

  const ref = useRef<SVGGElement>(null);

  React.useEffect(() => {
    if (!path) { return; }
    fetchGet(getIconUrl(path))
      .then(res => res.text())
      .then(xml => {
        const elem = ref.current;
        if (!elem) { return; }

        // Only keep what's inside the svg tag
        let fixedXml = xml
          .replace(/^.*?<svg[^>]*>/igs, "")
          .replace(/<\/svg>.*$/igs, "")
          .replace(/<title>[^<]*<\/title>/igs, ""); // Also remove useless title

        // Also keep the viewbox
        const viewBoxMatch = xml.match(/viewBox="([^"]*)"/);
        const viewBox = viewBoxMatch ? viewBoxMatch[1].split(" ").map(e => +e) : [0, 0, 113, 113];

        // Replace the colors
        fixedXml = fixedXml.replace(/#FFFFFF/ig, color)
          .replace(/#FFF/ig, color)
          .replace(/fill:white/ig, "fill:" + color)
          .replace(/fill: white/ig, "fill:" + color);

        // Insert the processed XML into the g element
        elem.innerHTML = fixedXml;

        // Scales
        const sx = size / viewBox[2];
        const sy = size / viewBox[3];

        // Make icons use the smaller of the scales so
        // they don't exceed size x size
        const s = sy > sx ? sx : sy;

        // Apply the correct transform to the g element
        const tx = x - s * (viewBox[0] + viewBox[2] / 2);
        const ty = centerTop ? y + 2 : y - s * (viewBox[1] + viewBox[3] / 2);
        elem.setAttribute("transform", `translate(${tx}, ${ty}) scale(${s})`);

        done();
      });
  }, [path, color, x, y, size, centerTop, done]);

  return <g id={`icon${index}`} ref={ref} data-path={path} />
}

const debugSquare = false;
const setDebugRect = debugSquare ? (id: number, x: number, y: number, w: number, h: number) => {
  const r = document.getElementById(`theRect${id}`);
  if (r && r instanceof SVGRectElement) {
    r.setAttribute("x", x.toString())
    r.setAttribute("y", y.toString())
    r.setAttribute("width", w.toString())
    r.setAttribute("height", h.toString())
  }
} : undefined;

function Text(props: {
  id: number, x: number, y: number, size: number, done: () => void, fill?: string, stroke?: string,
  strokeWidth?: number, font: string, rtl: boolean, fontWeight: number, url: string, text: string, separator?: string,
  notifyFinalSize?: (x: number, y: number, w: number, h: number) => void
}) {
  const { x, y, size, fill, stroke, strokeWidth, font, rtl, fontWeight, url, id, done, notifyFinalSize, separator, text: originalText } = props;
  const [textL1, textL2] = separator ? originalText.split(separator) : [originalText, undefined];

  if (!textL1 && !textL2) { done(); return <></> };

  let shouldRenderTextToPath = false;

  for (let c of textL1) {
    if (c.charCodeAt(0) > 255) {
      shouldRenderTextToPath = true;
      break;
    }
  }
  for (let c of textL2 ?? []) {
    if (c.charCodeAt(0) > 255) {
      shouldRenderTextToPath = true;
      break;
    }
  }

  // Force it enabled always anyways
  shouldRenderTextToPath = true;
  /*if ((window as any).FORCE_RENDER_TEXT !== undefined) {
    shouldRenderTextToPath = (window as any).FORCE_RENDER_TEXT;
    console.log("FORCING RENDER TEXT TO", shouldRenderTextToPath);
  }*/

  if (shouldRenderTextToPath) {
    OpenTypeLoad(url, function (err, font) {
      if (err || !font) {
        const errStr: string = err.toString?.();
        if (errStr?.indexOf?.("<!DO") === -1) {
          console.log('Font could not be loaded: ', err);
        }
        done();
      } else {
        try {
          let svg = "";
          let yOffset = 0;
          let bbx = 999999, bby = 999999, bbw = 0, bbh = 0;

          const renderTextLine = (text: string, rtl: boolean) => {
            let convertedText = text;
            if (rtl) {
              const runes: string[][] = [];

              let lastRtl = false;
              for (let rune of text) {
                const rtl = isCharRTL(rune);

                if ((lastRtl !== rtl && rune !== " ") || runes.length === 0) {
                  lastRtl = rtl;

                  // Any spaces at the end of a RTL change get separated into their own block
                  if (runes.length > 0 && runes[runes.length - 1][runes[runes.length - 1].length - 1] === " ") {
                    runes[runes.length - 1].pop();
                    runes.push([" "]);
                    runes.push([]);
                  }

                  runes.push([]);
                }

                // Keep individual spaces in their own block
                if (runes[runes.length - 1].length === 1 && runes[runes.length - 1][0] === " ") {
                  runes.push([]);
                }

                runes[runes.length - 1].push(rune);
              }
              convertedText = runes.map(r => ((isCharRTL(r?.[0]?.[0] ?? "")) ? r.reverse() : r).join("")).reverse().join("");
            }

            // Note that the (0, 0) coord in text is bottom-left instead of the usual top-left
            const { x1, x2, y1, y2 } = font.getPath(convertedText, 0, 0, size).getBoundingBox();

            const finalWidth = x2 - x1, finalHeight = y2 - y1;
            const finalX = x - finalWidth / 2;
            const finalY = y - finalHeight + yOffset;

            const path = font.getPath(convertedText, finalX - x1, finalY - y1, size);
            const path2 = Object.assign(path as any, { fill, stroke, strokeWidth }) as Path;
            svg += path2.toSVG(2);

            bbx = Math.min(finalX, bbx);
            bby = Math.min(finalY, bby);
            bbw = Math.max(finalWidth, bbw);
            bbh = finalHeight + yOffset;
            yOffset += size + 0.7; // Eyeball measure
          };

          textL1 && renderTextLine(textL1, rtl);
          textL2 && renderTextLine(textL2, rtl);
          notifyFinalSize?.(bbx, bby, bbw, bbh);
          setDebugRect?.(id, bbx, bby, bbw, bbh);

          const elem = document.getElementById(`textpath${id}`);
          if (elem) elem.innerHTML = svg;
        } catch (e) {
          console.log("Error loading font: " + url, e);
          const elem = document.getElementById(`textpath${id}`);
          if (elem) elem.innerHTML = "";
        }
        done();
      }
    })
  } else if (notifyFinalSize) {
    setTimeout(() => {
      const textGroup = document.getElementById(`text${id}`);
      if (!textGroup) return done();

      let x, y, width, height;
      if (!textL1 || !textL2) {
        const bbox = (textGroup.children[0] as SVGTextElement).getBBox();
        x = bbox.x;
        y = bbox.y;
        width = bbox.width;
        // If we only have the bottom text, we still have to keep the full height so the border doesn't get smaller
        height = textL1 ? bbox.height : bbox.height * 2;
      } else {
        const bbox1 = (textGroup.children[0] as SVGTextElement).getBBox();
        const bbox2 = (textGroup.children[1] as SVGTextElement).getBBox();
        x = Math.min(bbox1.x, bbox2.x);
        y = Math.min(bbox1.y, bbox2.y);
        width = Math.max(bbox1.width, bbox2.width);
        height = bbox1.height + bbox2.height;
      }

      notifyFinalSize?.(x, y, width, height);
      setDebugRect?.(id, x, y, width, height);
      done();
    }, 10); // Seems to work with 0 or setImmediate, but let's play it safe

  } else {
    done();
  }

  return <g id={`text${id}`}>
    {textL1 && !shouldRenderTextToPath && <text x={x} y={y} fontSize={size} fill={fill} fontFamily={font} fontWeight={fontWeight}
      stroke={stroke} strokeWidth={strokeWidth} textAnchor="middle">
      {textL1}
    </text>}

    {textL2 && !shouldRenderTextToPath && <text x={x} y={y + 1.1 * size} fontSize={size} fill={fill} fontFamily={font} fontWeight={fontWeight}
      stroke={stroke} strokeWidth={strokeWidth} textAnchor="middle">
      {textL2}
    </text>}

    {debugSquare && <rect id={`theRect${id}`} width="10" height="10" x="10" y="10" stroke="#000" fill="#00000000" />}
    {shouldRenderTextToPath && <g id={`textpath${id}`}></g>}
  </g>;

}

function LedCircle(props: { x: number, y: number, borderColor: string, fillColor: string, index: number, usePoints: boolean, isTL: boolean, isHotel: boolean, isTLH: boolean}) {
  const { x, y, borderColor, fillColor, index, usePoints, isTL, isHotel, isTLH} = props;
  const width = usePoints ? "0.5" : "0.176389";

  const sizeX =  isTL && !isTLH  ? 10 : 16; 
  const sizeY =  isTL && !isTLH ? 8 : 16; 

  const borderRadius = 2; 
  const x1 = x - sizeX / 2; 
  const y1 = (y - sizeY / 2) + (isTL && !isTLH ? 3.5 : 0) ; 

  if(!tactilArea){
    return (   
      <circle id={`ledCircle${index}`} r="1.15" cx={x} cy={y} fill={fillColor} stroke={borderColor} strokeWidth={width} opacity={isHotel  ? 0 : 1}/>
    );
  }
  else{
    return (
    
      <svg>
        <rect x={x1} y={y1} width={sizeX} height={sizeY} rx={borderRadius} ry={borderRadius} fill="none" stroke="gray" strokeWidth="0.1" strokeDasharray="1,1" opacity = {1}/>
        <circle id={`ledCircle${index}`} r="1.15" cx={x} cy={y} fill={fillColor} stroke={borderColor} strokeWidth={width} opacity={isHotel  ? 0 : 1}/>
      </svg>
  );
  }


}
