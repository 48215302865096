import isEqual from "react-fast-compare";
import { DefaultDesign, DesignData, UserData, ProjectData, SpecsMap, getModelNameGlassOnly } from "../types";
import React, { Component, useEffect, useRef, useState } from "react";
import { t } from "ttag";
import { toast } from "react-toastify";
import {
  FaAngleLeft, FaEllipsisV, FaSave, FaTimes, FaShareSquare, FaClone, FaSortAmountDown, FaSortAmountUp,
  FaUser, FaHourglassHalf, FaPlusCircle, FaPlusSquare, FaMinusSquare, FaUndo, FaCheckCircle
} from "react-icons/fa";
import { fetchGet, fetchPost, getUsrThumbUrl, handler, setToken, boxShadow, MAX_DESIGNS, shiftTop, loading, blobToDataURL, MODE, isAdminInpersonate, inpersonateGoBack, parseJson, isMobile, isLandscapeDesign, isIPhone } from "../util";
import AccountInfoComponent from "./AccountInfo";
import EditPageComponent from "./EditPage";
import OrderComponent from "./Order";
import DeleteDesignModalComponent from "./Modals/DeleteDesignModal";
import DeleteProjectModalComponent from "./Modals/DeleteProjectModal";
import SaveChangesModalComponent from "./Modals/SaveChangesModal";
import SendOrderModalComponent from "./Modals/SendOrderModal";
import NewProjectModalComponent from "./Modals/NewProjectModal";
import DuplicateProjectModalComponent from "./Modals/DuplicateProjectModal"
import RenameProjectModalComponent from "./Modals/RenameProjectModal";
import { Modal, SelectProject } from "./shared";
import { ReactComponent as CubLogo } from "../resources/logo_cub.svg";
import { ReactComponent as CubLogoShort } from "../resources/logo_cub_short.svg";
import ModelSVGComponent from "./ModelSVG";
import { timeStamp } from "console";
import { parseRoomNumbers } from "../parser";
import { isApp, appInfoSetup, callAngularFunction } from "./shared";

type MainPageProps = {
  user: UserData,
  updateHandler: (register?: boolean) => void,
}

enum ProjectSortField {
  created_at, updated_at, status, name
}

type MainPageState = {
  editing?: DesignData,
  projects: ProjectData[],
  loading: boolean,

  orderProject?: ProjectData,

  designToClone?: DesignData,
  designToDelete?: DesignData,
  designToEdit?: DesignData,
  targetProject?: string,

  expandedProject?: string,
  projectToDelete?: ProjectData,
  projectToDuplicate?: ProjectData,
  projectToRename?: ProjectData,
  projectSortField: ProjectSortField,
  projectSortAscending: boolean,
  units: Array<number>,
  orderCode: String,
  showDeleteDesign: boolean,
  showNewProjectModal: boolean,
  showDeleteProjectModal: boolean,
  showEditingModal: boolean,
  showDuplicateProjectModal: boolean,
  showRenameProjectModal: boolean,
  showSendOrderConfirmationModal: boolean,
  newProjectName: string,

  // PDF Export
  downloadDesign?: DesignData,
  downloadFinished?: (svg: Blob | string) => void,
}

const ORDER_BUTTON_ID = "orderModalButton";
const DELETE_DESIGN = "deleteDesignModalButton";
const DELETE_PROJECT = "deleteProjectModalButton";
const NEW_PROJECT_BTN_ID = "newProjectModalButton";
const PROJECT_BUTTON_ID = "projectModalButton";
const CONFIRM_SEND_ORDER_BUTTON = "confirmSendOrderModalButton";
const SAVE_CHANGES_BUTTON = "saveChangesModalButton";
const DUPLICATE_PROJECT_BUTTON = "saveChangesModalButton";
const RENAME_PROJECT_BUTTON = "renameProjectButton";
const PROJECT_SORT_COOKIE = "projectSort";
const PROJECT_SORT_ASC_COOKIE = "projectSortAsc";

export default class MainPageComponent extends Component<MainPageProps, MainPageState> {
  canvasRef = React.createRef<ModelSVGComponent>();
  editorRef = React.createRef<EditPageComponent>();
  orderFieldRef = React.createRef<HTMLInputElement>();

  constructor(props: MainPageProps) {
    super(props);

    const sort = (window.localStorage.getItem(PROJECT_SORT_COOKIE) ?? "created_at") as keyof typeof ProjectSortField;
    const asc: boolean = window.localStorage.getItem(PROJECT_SORT_ASC_COOKIE) === "true";
    this.state = {
       projects: [], 
       loading: true, 
       projectSortField: ProjectSortField[sort], 
       projectSortAscending: asc ?? false,
       units: [],  
       orderCode: "", 
       showDeleteDesign: false,
       showNewProjectModal: false,
       showDeleteProjectModal: false,
       showEditingModal: false,
       showDuplicateProjectModal: false,
       showRenameProjectModal: false,
       showSendOrderConfirmationModal: false,
       newProjectName: "",
      };
      
  }

  render() {
    const sort = {
      field: this.state.projectSortField, setField: (f: ProjectSortField) => {
        window.localStorage.setItem(PROJECT_SORT_COOKIE, ProjectSortField[f])
        this.setState({ projectSortField: f })
      },
      ascending: this.state.projectSortAscending, setAscending: (b: boolean) => {
        window.localStorage.setItem(PROJECT_SORT_ASC_COOKIE, String(b))
        this.setState({ projectSortAscending: b })
      },
    };
    return (
      <div className="h-100vh d-flex flex-column">
        <Navbar editing={this.state.editing} editorRef={this.editorRef} newProject={this.newProject} sort={sort}
          editHandler={this.editHandler} logoutHandler={this.logoutHandler} name={this.props.user.name} />
        <div className="h-100 of-y-auto of-x-hidden scroller">
          <AccountInfoComponent name={this.props.user.name} email={this.props.user.email} />
          {this.content()}
        </div>
      </div>
      
    );
  }
  content = () => {
    const { editing, projects, expandedProject, designToClone, orderProject, downloadDesign, downloadFinished,showDuplicateProjectModal, showDeleteDesign, projectToRename,projectToDelete,projectToDuplicate, showEditingModal,showRenameProjectModal, showDeleteProjectModal, showNewProjectModal, targetProject, designToDelete, showSendOrderConfirmationModal } = this.state;
  
    const projectOptions = projects.map(p => ({ label: p.name, value: p.id, enabled: p.status === "created" }));
  
    // Si estamos en modo de edición, mostramos el componente de edición.
    if (editing) {
      return <EditPageComponent ref={this.editorRef} editing={editing} projects={projectOptions} />;
    } 
    // Si no hay proyectos, mostramos el mensaje correspondiente.
    else if (projects.length <= 0) {
      return (
        <div className="d-flex align-items-center">
          <p className="mx-auto mt-5 text-light">{
            this.state.loading
              ? t`Loading projects...`
              : t`No projects saved, please create a new one by clicking 'New project'`
          }</p>
        </div>
      );
    } else {
      const numDesigns = projects.reduce((prev, curr) => prev + curr.designs.length, 0);
  
      return (
        <>
          {/* Modal para seleccionar o crear un proyecto */}
          <Modal 
            btnId={PROJECT_BUTTON_ID} 
            title={t`Create or select the project`} 
            height={400} 
            footer={
              <div>
                <button 
                  type="button" 
                  className="btn btn-primary"
                  onClick={() => {
                    const pid = targetProject;
  
                    fetchPost(`/api/design/${designToClone!.id}/clone/${pid}`).then(res => {
                      if (!res.ok) {
                        toast.error(t`Error cloning design`);
                      }
                      this.reloadProjects();
                    });
  
                    document.getElementById(PROJECT_BUTTON_ID)!.click();
                  }}
                >
                  {t`Save`}
                </button>
              </div>
            }
            > 
            <SelectProject options={projectOptions}
                onSelected={p => this.setState({ targetProject: p ? p.value : undefined })}
                onCreated={() => null} />
          </Modal>

          {showDeleteDesign && (
            <DeleteDesignModalComponent 
              buttonId={DELETE_DESIGN}
              design={designToDelete as DesignData}
              onConfirmDeleted={this.deleteDesign}
            />
          )}

          {showDeleteProjectModal && (
            <DeleteProjectModalComponent 
              buttonId={DELETE_PROJECT}
              project={projectToDelete as ProjectData}
              onConfirmDeleted={this.deleteFullProject}
            />
          )}

          {showRenameProjectModal && (
            <RenameProjectModalComponent 
            key={new Date().getTime()}
            buttonId={RENAME_PROJECT_BUTTON}
            projectID={projectToRename?.id as string}
            onCreateProject={(name: string, id: string) => this.checkNewProjectNameRename(name, id)}
            newProjectName={this.state.newProjectName}
            />
          )}

          {showSendOrderConfirmationModal &&
            <SendOrderModalComponent
              buttonId={CONFIRM_SEND_ORDER_BUTTON}
              onConfirmSend={this.finishSendOrder}

            />
          }
          {showNewProjectModal && (
              <NewProjectModalComponent
              key={new Date().getTime()}
              buttonId={NEW_PROJECT_BTN_ID}
              onCreateProject={(name: string) => this.checkNewProjectName(name)}
              newProjectName={this.state.newProjectName}
            />
          )}
          
          {showDuplicateProjectModal && (
              <DuplicateProjectModalComponent
              key={new Date().getTime()}
              buttonId={DUPLICATE_PROJECT_BUTTON}
              projectID={projectToDuplicate?.id as string}
              onCreateProject={(name: string, id: string) => this.checkNewProjectNameDuplicate(name, id)}
              newProjectName={this.state.newProjectName}
            />
          )}

          {/* Modal para el proyecto de orden */}
          {orderProject && (
            <OrderComponent 
              buttonId={ORDER_BUTTON_ID}
              project={orderProject} 
              sendOrderDialogMobile={this.showDialogAux}
              sendMailList={(project: ProjectData, sendMail: boolean) => this.exportDesignList(project, sendMail)}
              onOrderPlaced={this.reloadProjects} 
            />
          )}
  
          {/* Renderiza la lista de proyectos */}
          <div className="row justify-content-center m-0">
            <div id="designsList" className="accordion pt-2 col-12 col-lg-11 col-xl-9">
              {projects.sort(this.projectSort).map((p, i) => {
                const empty = !p.designs || p.designs.length <= 0;
                const orderInProcess = p.status !== "created";
                const canEdit = !orderInProcess || isAdminInpersonate();
  
                const isExpanded = (!expandedProject && i === 0) || (expandedProject && p.id === expandedProject);
  
                return (
                  <div key={p.id} className="card m-2 bg-dark" style={{ overflow: "visible", boxShadow }}>
                    <div id={`heading-${p.id}`} className="card-header d-flex">
                      <div className="stretch-contain d-flex flex-grow-1" style={{ minWidth: 0 }}>
                        <button className={`d-block btn btn-link ${isExpanded ? "" : "collapsed"} text-light text-decoration-none stretched-link text-ellipsis`}
                          type="button" data-toggle="collapse" data-target={`#collapse-${p.id}`}>
                          <FaPlusSquare className="plus-collapse" style={{ verticalAlign: -2.5 }} />
                          <FaMinusSquare className="minus-collapse" style={{ verticalAlign: -2.5 }} />
                          <span className="pl-3">{p.name || t`Unnamed project`}</span>
                        </button>
                      </div>
  
                      <div className="dropdown d-flex flex-shrink-0">
                        {orderInProcess && <span className="py-1 mx-2">
                          {p.status === "done" ?
                            <FaCheckCircle style={{ verticalAlign: -3.5, color: "white" }} /> :
                            <FaHourglassHalf style={{ verticalAlign: -3.5, color: "white" }} />
                          }
                          <small className="text-light ml-2">
                            {
                              p.status === "waiting-validate" ? t`Validating order` :
                                p.status === "waiting-printing" ? t`Printing order` :
                                  p.status === "done" ? t`Order complete` :
                                    t`Unknown status` + ` (${p.status})`
                            }
                          </small>
                        </span>}
                        {!orderInProcess && !empty && <a href="#n" role="button" className="py-1 mx-2 text-light text-decoration-none"
                          title={t`Send for printing`}
                          onClick={handler(this.prepareOrder, p.id)}>
                          <FaShareSquare style={{ verticalAlign: -3.5 }} />
                          <small className="hide-mobile pl-2">{t`Finish project`}</small>
                        </a>}
                        <a href="#d" role="button" data-toggle="dropdown" className="py-1 px-2 text-light">
                          <FaEllipsisV />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <a className="dropdown-item" href="#r"
                            onClick={handler(this.exportProject, p)}>{t`Export design (.PDF)`}</a>
                          {/*<a className="dropdown-item" href="#r" onClick={handler(this.sendOrder, p.id)}>{t`Send configuration`}</a>*/}
                          {orderInProcess && <a className="dropdown-item" href="#r" onClick={handler(this.exportDesignList, p, false)}>{t`Download order list`}</a>}
                          <a className="dropdown-item" href="#r"
                            onClick={handler(this.renameProject, p.id)}>{t`Rename project`}</a>
                          <a className="dropdown-item" href="#d"
                            onClick={handler(this.duplicateProject, p.id)}>{t`Duplicate project`}</a>
                          {!orderInProcess && <a className="dropdown-item" href="#e"
                            onClick={handler(this.deleteProject, p.id, p.name)}>{t`Delete project`}</a>}
                        </div>
                      </div>
                    </div>
                    <div id={`collapse-${p.id}`} data-parent="#designsList"
                      className={`collapse ${isExpanded ? "show" : ""}`}>
                      <div className="card-body d-flex flex-wrap justify-content-center-mobile">
                        {p.designs?.sort((a, b) => Date.parse(a.created_at!) - Date.parse(b.created_at!))
                          .map(p => {
                            return (
                              <Card design={p} key={p.id}
                                cloneHandler={this.cloneHandler}
                                editHandler={canEdit ? this.editHandler : undefined}
                                deleteHandler={canEdit ? this.deleteHandler : undefined} />
                            );
                          })}
  
                        {!orderInProcess && <CardNew editHandler={this.editHandler} projectId={p.id} numDesigns={numDesigns} user={this.props.user} />}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div id="bottom-bar-spacer" />
            </div>
          </div>
  
          {/* Componente para manejar la descarga del diseño */}
          <ModelSVGComponent ref={this.canvasRef} format={"png"} pngWidth={500}
            justReturn={true} height={0} design={downloadDesign}
            downloadFinished={svg => this.setState({ downloadDesign: undefined }, () => downloadFinished?.(svg))} />     
        </>
      );
    }
  }
  
  deleteHandler = (design: DesignData) => {
        if(isMobile()) // && isIPhone())
        {
          this.setState({showDeleteDesign: true});
          this.setState(
            () => ({ designToDelete: design }),
            () => document.getElementById(DELETE_DESIGN)!.click()
          );
          
          return;
        }

        const confirm = window.confirm(t`Are you sure you want to delete '${design.name.includes("{{") ? design.name.slice(0, -7) : design.name}'`);
        if (confirm) {
          this.setState({ designToDelete: design }, () => {          
            this.deleteDesign();
          });
        }
  
  }

  deleteDesign = () => 
  {
    const {designToDelete} = this.state;
    if(!designToDelete)return;
    fetchPost(`/api/design/${designToDelete.id}/delete`).then(res => {
      if (!res.ok) {
        toast.error(t`Error deleting design`);
      } else {
        toast.success(t`Design removed successfully`);
      }
      this.reloadProjects();
    });
  }

  deleteFullProject = () => 
  {
    const {projectToDelete} = this.state;
    if(!projectToDelete)return;
    fetchPost(`/api/project/${projectToDelete?.id}/delete`)
    .then(res => {
      if (!res.ok) {
        toast.error(t`Error deleting project`);
      } else {
        toast.success(t`Project removed successfully`);
      }
      this.reloadProjects();
    });
  }

  cloneHandler = (design: DesignData) => {
    this.setState({ designToClone: design }, () => {
      document.getElementById(PROJECT_BUTTON_ID)!.click();
    });
  }


  editHandler = (design?: DesignData, confirmSave?: boolean) => {
    shiftTop();
    

    if (!design) {
      const editor = this.editorRef.current;
      if(isMobile())
      {
       /* loading(true);
        editor?.saveDesign(this.editHandler); 
        loading(false);
        this.setState({ editing: design });*/
        this.setState({ editing: design });
        return;
      }
      if (confirmSave
        && editor
        && !isEqual(this.state.editing, editor.state.design)
        && !window.confirm(t`There are unsaved changes, are you sure you want to exit?`)) {
        return;
      }   
      loading(true);
      this.reloadProjects(); 
     
    } else {
      this.setState({ expandedProject: design.project_id });
    }    
    this.setState({ editing: design });
  } 


  logoutHandler = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    setToken(undefined);
    this.props.updateHandler(false);
  }

  scrollToSelected = () => {
    const elem = document.querySelector(".collapse.show");
    const parent = elem?.parentElement || elem?.parentNode;

    (parent as any)?.scrollIntoView?.();
  }

  reloadProjects = () => {
    this.scrollToSelected();
    fetchGet('/api/project')
      .then(res => {
        if (res.ok) {
          res.json().then(p => this.setState({ projects: p, loading: false }, () => { this.scrollToSelected(); loading(false); }));
        } else {
          toast.error(t`Unknown error`);
          this.logoutHandler();
        }
      });
  }

  componentDidMount() {
    this.reloadProjects();
  }
 

  newProject = () => {

    if(isMobile())// && isIPhone())
    {

      this.setState(
        {
          showNewProjectModal: true,
          newProjectName: "", 
        },
        () => {          
          document.getElementById(NEW_PROJECT_BTN_ID)!.click(); 
        }
      );

      return;
    }
    const name = prompt(t`Input the new project name`);
    this.checkNewProjectName(name as string);
  }

  checkNewProjectName(name: string)
  {
    if (!name) {
      return;
    }

    if (this.state.projects.find(p => p.name === name)) {
      toast.error(t`This name is already in use`);
      return;
    }

    const data = JSON.stringify({ name });
    fetchPost("/api/project", data)
      .then(_ => this.reloadProjects());
  }

  checkNewProjectNameDuplicate(name: string, id: string)
  {
    if (!name) {
      return;
    }

    if (this.state.projects.find(p => p.name === name)) {
      toast.error(t`This name is already in use`);
      return;
    }

    const data = JSON.stringify({ name });
    fetchPost(`/api/project/${id}/duplicate`, data)
      .then(_ => this.reloadProjects());
  }

  checkNewProjectNameRename(name: string, id: string)
  {
    if (!name) {
      return;
    }

    if (this.state.projects.find(p => p.name === name)) {
      toast.error(t`This name is already in use`);
      return;
    }

    const data = JSON.stringify({ name });
    fetchPost(`/api/project/${id}/rename`, data)
      .then(_ => this.reloadProjects());
  }

  exportProject = async (project: ProjectData) => {
      loading(true);

      const { default: jsPDF } = await import("jspdf");
      const doc = new jsPDF('p', 'mm', 'a4');

      const margin = 10; // Márgenes
      const pageWidth = doc.internal.pageSize.getWidth(); // Ancho de la página
      const pageHeight = doc.internal.pageSize.getHeight(); // Alto de la página
      const designWidth = ((pageWidth / 2) - (margin * 2) - 15); // Ancho de cada diseño
      const designHeight = 100; // Altura fija para los diseños
      const initialYPos = 30; // Posición inicial Y para el primer diseño en cada página

      // Encabezado general
      const addHeader = () => {
          doc.setFontSize(14);
          doc.setDrawColor("#000");
          doc.text(MODE === "bes" ? "CUBIK by Ingenium" : "CUBIC by Ingenium", 20, 20);

          doc.setFontSize(10);
          doc.setDrawColor("#333");
          doc.text(project.name + (project.order_code ? " (" + project.order_code + ")" : ""), 190, 20, { align: "right" });

          doc.setLineWidth(0.2);
          doc.setDrawColor("#888");
          doc.line(20, 22, pageWidth - 20, 22);
      };

      // Pie de página
      const addFooter = (currentPage: number,  totalPages:  number) => {
          doc.setFontSize(10);
          doc.setDrawColor("#000");
          doc.text('Page ' + currentPage + ' of ' + totalPages, pageWidth / 2, pageHeight - 10, { align: "center" });
      };

      for (let i = 0; i < project.designs.length; i++) {
          const design = project.designs[i];

          const url = getUsrThumbUrl(design.id, design.updated_at || "");
          const res = await fetch(url);
          const blob = await res.blob();
          const dataUrl = await blobToDataURL(blob);
          const specs = SpecsMap[design.model];
          const [w, h] = specs.dimensions;

          if (i % 2 === 0) {
              if (i > 0) {
                  addFooter(Math.floor((i + 1) / 2), Math.ceil(project.designs.length / 2)); // Add footer for previous page
                  doc.addPage();
                  
              }
              addHeader();
          }

          const xPos = (i % 2 === 0) ? 22 : (pageWidth / 2);
          const yPos = initialYPos;

          const imgWidth = Math.min(designWidth, w);
          const imgHeight = (h * imgWidth) / w;

          // Set line width for the rectangle
          doc.setLineWidth(0.01); // Thinner line width for rectangle outline
          doc.setDrawColor("#000");
          doc.rect(xPos - 1, (yPos + 15) - 1, imgWidth + 2, imgHeight + 2, "D"); // Draw filled rectangle
          doc.addImage(dataUrl, "PNG", xPos, yPos + 15, imgWidth, imgHeight, undefined, "FAST");

          doc.setFontSize(10);
          doc.text(design.name.includes("{{") ? design.name.slice(0, -7) : design.name, xPos, yPos + 10);
    

          if (specs.extra === "vh") {
              const numbers = parseJson(design.order_extra).numbers;
              const numbersSplit = numbers?.split(",") || ["None"];
              let toPrint = "";

              let roomYPos = yPos + imgHeight + 30;
              doc.setFontSize(10);
              doc.text(t`Room numbers` + ": (" + parseJson(design.order_extra).prefix + ")", xPos, roomYPos - 5);

              for (let j = 0; j < numbersSplit.length; j++) {
                  toPrint += numbersSplit[j];

                  if (j !== numbersSplit.length - 1) {
                      toPrint += ", ";
                  }

                  if (toPrint.length >= 25 || j === numbersSplit.length - 1) {
                      roomYPos += 5;
                      doc.text(toPrint.trim(), xPos + 5, roomYPos);
                      toPrint = "";
                  }
              }
          }
      }

      // Finalize the last page footer
      addFooter(Math.floor((project.designs.length + 1) / 2), Math.ceil(project.designs.length / 2));

      loading(false);
      doc.save(project.name + ".pdf");
      return;
  }

  exportProjectOld = async (project: ProjectData) => {
    const images: { design: DesignData, img: Blob | string }[] = [];

    loading(true);

    const { default: jsPDF } = await import("jspdf");
    const doc = new jsPDF('p', 'mm', 'a4');

    for (let i in project.designs) {
      const design = project.designs[i];

      const url = getUsrThumbUrl(design.id, design.updated_at || "");

      const res = await fetch(url);
      const blob = await res.blob();

      const dataUrl = await blobToDataURL(blob);
      const specs = SpecsMap[design.model];
      const [w, h] = specs.dimensions;
      const isLandscape = isLandscapeDesign(design)

      //header
      doc.setFontSize(14);
      doc.setDrawColor("#000")
      doc.text(MODE === "bes" ? "CUBIK by Ingenium" : "CUBIC by Ingenium", 20, 20);

      doc.setFontSize(10);
      doc.setDrawColor("#333")
      doc.text(project.name + (project.order_code ? " (" + project.order_code + ")" : ""), 190, 20, { align: "right" });

      doc.setLineWidth(0.2);
      doc.setDrawColor("#888")
      doc.line(20, 22, 190, 22);

      // Footer
      doc.setFontSize(10);
      doc.setDrawColor("#000")
      doc.text('Page ' + (+i + 1) + ' of ' + project.designs.length, 105, 285, { align: "center", baseline: "bottom" });

      doc.setLineWidth(0.2);
      doc.setDrawColor("#888")
      doc.line(20, 280, 190, 280);
      // ----------
      
      doc.setDrawColor("#000")
      doc.setFillColor("#888");
      const margin = 0.4;
      doc.rect( 30 - margin,  (isLandscape ? 80 : 30) - margin, w + margin * 2, h + margin * 2, "FD");
      doc.addImage(dataUrl, "PNG", 30, (isLandscape ? 80 : 30), w, h, undefined, "FAST" as any);
      
   
      doc.setDrawColor("#000")
      doc.setFontSize(16);
      doc.text(design.name.includes("{{") ? design.name.slice(0,-7) : design.name, (isLandscape ? 30 : 130), (isLandscape ? 30 : 40), { maxWidth: 60 });
      doc.setFontSize(10);
      var comelitID = design.name.split("{{")[1];
      doc.text(`${specs.name} (Ref: ${comelitID}${design.model})`, (isLandscape ? 30 : 130), (isLandscape ? 45 : 55));

      if (specs.extra === "vh") {
        const numbers = parseJson(design.order_extra).numbers;
        const numbersSplit = numbers?.split(",") || ["None"];
        let toPrint = "";

        let yPos = 60;
        let yPosSkip = 5;
        const last = numbersSplit.length - 1;

        doc.setFontSize(10);
        doc.text(t`Room numbers` + ": (" + parseJson(design.order_extra).prefix + ")", (isLandscape ? 30 : 130),  (isLandscape ? yPos-5 : yPos));

        for (let i = 0; i < numbersSplit.length; i++) {
          toPrint += numbersSplit[i]

          if (i !== last) {
            toPrint += ", "
          }

          if (toPrint.length >= 25 || i === last) {
            yPos += yPosSkip;
            doc.text(toPrint.trim(), (isLandscape ? 30 : 130), yPos);
            toPrint = "";
          }
        }
      }

      +i < project.designs.length - 1 && doc.addPage();
    }

    loading(false);
    doc.save(project.name + ".pdf");
    return;
  }

  exportDesignList = async (project: ProjectData, sendMail: boolean) => {
      loading(true);

      const { default: jsPDF } = await import("jspdf");
      const doc = new jsPDF('p', 'mm', 'a4');

      doc.setFontSize(14);
      doc.setDrawColor("#000");
      doc.text(MODE === "bes" ? "CUBIK by Ingenium" : "CUBIC by Ingenium", 20, 20);

      doc.setFontSize(10);
      doc.setDrawColor("#333");
      doc.text(project.name + (project.order_code ? " (" + project.order_code + ")" : ""), 190, 20, { align: "right" });

      doc.setLineWidth(0.2);
      doc.setDrawColor("#888");
      doc.line(20, 22, 190, 22);

      const margin = 22;
      const maxImgWidth = 30;
      const maxImgHeight = 30; 
      const rowHeight = 40; 
      const tableWidth = doc.internal.pageSize.getWidth() - (2 * margin); 
      const cellPadding = 5;
      let tableYPos = 30;

     
      // Dibujar encabezados de la tabla
      doc.setFont("helvetica", "bold",);
      doc.text(t`Model`.toUpperCase(), margin, tableYPos);
      doc.text(t`Units`.toUpperCase(), margin + tableWidth / 3, tableYPos);
      doc.text(t`Design`.toUpperCase(), margin + (2 * tableWidth) / 3, tableYPos);
      tableYPos += rowHeight;

      // Rellenar la tabla
      doc.setFont("helvetica", "normal");

      for (let index = 0; index < project.designs.length; index++) {
        
          const design = project.designs[index];
          const comelitID = design.name.slice(-5) + getModelNameGlassOnly(design.model, design.glass_only);
          const quantity = design.order_quantity || 1;
          const url = getUsrThumbUrl(design.id, design.updated_at || "");

          const res = await fetch(url);
          const blob = await res.blob();
          const dataUrl = await blobToDataURL(blob);

          const img = new Image();
          img.src = dataUrl;
          await new Promise((resolve) => (img.onload = resolve)); 

          const aspectRatio = img.width / img.height;
          let imgDisplayWidth, imgDisplayHeight;

          if (aspectRatio > 1) { 
              imgDisplayWidth = maxImgWidth;
              imgDisplayHeight = maxImgWidth / aspectRatio;
          } else { 
              imgDisplayHeight = maxImgHeight;
              imgDisplayWidth = maxImgHeight * aspectRatio;
          }

          const cellHeight = rowHeight - cellPadding;
          const imgXPos = margin + (2 * tableWidth) / 3 + 5; 
          const imgYPos = (tableYPos - imgDisplayHeight) - (isLandscapeDesign(design) ?  7 : 2.5); 
          doc.addImage(dataUrl, "PNG", imgXPos, imgYPos, imgDisplayWidth, imgDisplayHeight, undefined, "FAST");


          doc.text(comelitID, margin + 5, tableYPos - 15);
          doc.text(String(quantity), (margin + 5) + tableWidth / 3, tableYPos - 15);
          

          doc.setDrawColor("#000");
          doc.setLineWidth(0.1);
        
          doc.rect(margin, tableYPos - cellHeight, tableWidth / 3, cellHeight); 
          doc.rect(margin + tableWidth / 3, tableYPos - cellHeight, tableWidth / 3, cellHeight); 
          doc.rect(margin + (2 * tableWidth) / 3, tableYPos - cellHeight, tableWidth / 3, cellHeight);
          doc.rect(imgXPos, imgYPos, imgDisplayWidth, imgDisplayHeight);
          tableYPos += rowHeight; 

          if (tableYPos + rowHeight > doc.internal.pageSize.getHeight()) {
              doc.addPage();
              tableYPos = 30; 
              doc.setFont("helvetica", "bold");
              doc.text("MODELO", margin, tableYPos);
              doc.text("CANTIDAD", margin + tableWidth / 3, tableYPos);
              doc.text("DISEÑO", margin + (2 * tableWidth) / 3, tableYPos);
              tableYPos += rowHeight;
          }
      }

      doc.setFontSize(10);
      doc.setDrawColor("#000");
      doc.text(t`Total designs:` + project.designs.length, 105, 285, { align: "center", baseline: "bottom" });

      loading(false);
     

      if(sendMail)
      {
          const pdfBlob = doc.output('blob');
          const file = new File([pdfBlob], `${project.name}_orderList.pdf`, { type: 'application/pdf' });
          const formData = new FormData();
          formData.append('pdf', file);
          formData.append('id', project.id);
          formData.append('orderCode', project.newOrderCode);
          formData.append('country', window.sessionStorage.getItem('country') ?? '');
          const response = await fetchPost('api/project/sendConfiguration',formData);

         /* if (!response.ok) {
              toast.error("No se ha podido enviar la configuración");
          } 
          else
          {
            //toast.success("La configuración ha sido enviada correctamente");
          }*/
      }
      else
      {
         doc.save(project.name.trim().replaceAll(" ", "_") + "_orderList" + ".pdf");
      }
      return;
  };

  renameProject = (id: string) => {
        
    if(isMobile())// && isIPhone())
    {

      this.setState({showRenameProjectModal: true});
      this.setState(
        () => ({ projectToRename: this.state.projects.find(p => p.id === id) }),
        () => document.getElementById(RENAME_PROJECT_BUTTON)!.click()
      );
      return;
    }
    const name = prompt(t`Input the new project name`);
    this.checkNewProjectNameRename(name as string, id);
  }

  deleteProject = (id: string, name: string) => {

    if(isMobile())// && isIPhone())
    {
      this.setState({showDeleteProjectModal: true});
      this.setState(
        () => ({ projectToDelete: this.state.projects.find(p => p.id === id) }),
        () => document.getElementById(DELETE_PROJECT)!.click()
      );
      return;
    }

   
    const confirm = window.confirm(t`Project ${name} and it's contents are going to be deleted, continue?`);

    if (confirm) {
      this.setState({projectToDelete: this.state.projects.find(p => p.id === id)}, () => {          
        this.deleteFullProject();
      });

    }
  }
  
  sendOrder = async (id:string) => {
    
  this.setState({ orderProject: this.state.projects.find(p => p.id === id)}, async () => {          
   
    this.exportDesignList(this.state.orderProject as ProjectData, true);
   /* if(isMobile())
    {
      this.showDialogAux();
      return;
    }
    const confirmed = window.confirm(
       t`Once the project is finished, it cannot be modified again and an order must be placed with the new generated codes.`
    );

  
    if (!confirmed) return;
      this.exportDesignList(this.state.orderProject as ProjectData, true);
      //this.finishSendOrder();   
   */
    });
  }

  showDialogAux=()=>
  {
    this.setState({ showSendOrderConfirmationModal:true}, async () => {      
      document.getElementById(CONFIRM_SEND_ORDER_BUTTON)!.click()  
    });
  }

  finishSendOrder= () => 
    {

      const {orderProject} = this.state;
      if(!orderProject)return;
      console.log("orderProject.newOrderCode antes de enviar:", orderProject.newOrderCode);

      const units =   (orderProject as ProjectData).designs.map(d => {
        const isVH = SpecsMap[d.model].extra === "vh";
        return (isVH && d.order_extra) ? (parseRoomNumbers(parseJson(d.order_extra)?.numbers)?.[0]?.length ?? d.order_quantity ?? 1) : (d.order_quantity ?? 1);
      });

      loading(true);

      const orders = units
        .map((quantity, i) => ({ design_id: orderProject.designs[i].id, quantity }))
        .filter(o => o.quantity > 0);

     
      const body = JSON.stringify({ code: orderProject.id, orderCode: orderProject.newOrderCode, orders });
      fetchPost(`/api/project/${orderProject.id}/order`, body)
      .then(async (res) => { 
        if (res.ok) {
          toast.success(t`Order sent successfully!`);
          this.exportDesignList(orderProject, true);
          this.reloadProjects();
        } else {
          const json = await res.json(); 
          const e = json.errors;
          if (e?.code && (e.code as string[]).includes("validation.unique")) {
            toast.error(t`This order code already has an order placed`);
          } else {
            toast.error(t`Error sending order`);
          }
        }
      });

    loading(false);
  }

  duplicateProject = (id: string) => {

    
    if(isMobile())// && isIPhone())
    {

      this.setState({showDuplicateProjectModal: true});
      this.setState(
        () => ({ projectToDuplicate: this.state.projects.find(p => p.id === id) }),
        () => document.getElementById(DUPLICATE_PROJECT_BUTTON)!.click()
      );

      return;
    }

    const name = prompt(t`Input the new project name`);
    this.checkNewProjectNameDuplicate(name as string, id);
  }

  prepareOrder = (id: string) => {
    this.setState(
      () => ({ orderProject: this.state.projects.find(p => p.id === id) }),
      () => document.getElementById(ORDER_BUTTON_ID)!.click()
    );
  }

  projectSort = (a: ProjectData, b: ProjectData) => {
    let res = 0;

    switch (this.state.projectSortField) {
      case ProjectSortField.created_at:
        res = Date.parse(b.created_at!) - Date.parse(a.created_at!);
        break;

      case ProjectSortField.updated_at:
        res = Date.parse(b.updated_at!) - Date.parse(a.updated_at!);
        break;

      case ProjectSortField.status:
        res = b.status.localeCompare(a.status);
        break;

      case ProjectSortField.name:
        res = b.name.localeCompare(a.name);
        break;
    }

    if (this.state.projectSortAscending) { res *= -1; }

    return res;
  };


  
}

type CardProps = {
  design: DesignData,
  cloneHandler: (p: DesignData) => void,
  editHandler?: (p: DesignData) => void,
  deleteHandler?: (p: DesignData) => void,
}

function Card(props: CardProps) {
  const { design, cloneHandler, editHandler, deleteHandler } = props;
  const { id, name, updated_at } = design;
  

  const cardTitle = editHandler ? t`Edit ${name.includes("{{") ? name.slice(0,-7) : name}` : t`Can't edit already started order`;
  const opacity = editHandler ? 1 : 0.6;
  const cursor = editHandler ? "pointer" : "";

  const pointerEvents = editHandler ? "inherit" : "none";
  
  const isLandscape = isLandscapeDesign(design);
  
  return (
    <div className="card hover-primary text-white" onClick={handler(editHandler, design)} title={cardTitle}
      style={{ width: 200, height: 390, margin: 10, cursor, opacity, pointerEvents, backgroundColor: "rgba(0,0,0,0.2)" }}>
      <button className="btn btn-primary btn-circle hover-bg-white" title={t`Clone`}
        onClick={handler(cloneHandler, design)} style={{ position: "absolute", top: 4, left: 4, boxShadow, padding: 6 }}>
        <FaClone className="text-dark" fontSize={15} />
      </button>
      {deleteHandler && <button className="btn btn-primary btn-circle hover-bg-white" title={t`Delete`}
        onClick={handler(deleteHandler, design)} style={{ position: "absolute", top: 4, right: 4, boxShadow, padding: 5  }}>
        <FaTimes className="text-dark" fontSize={18} />
      </button>}
      <div className="d-flex rounded-top w-100 p-2" style={{ height: 350}} >
        {isMobile() &&  <img src={getUsrThumbUrl(id, updated_at || "")} width="120px" decoding="async" loading="lazy"
          className="m-auto" alt="..." style={{ boxShadow, borderRadius: 5, border: "1px solid gray"}} />
        }
        
        {!isMobile()  && (isLandscape || (!isLandscape &&  SpecsMap[design.model].extra == "card")) &&  <img src={getUsrThumbUrl(id, updated_at || "")} width="180px" decoding="async" loading="lazy"
          className="m-auto" alt="..." style={{ boxShadow, borderRadius: 5, border: "1px solid gray"}} />
        }

        {!isMobile()  && !isLandscape &&  SpecsMap[design.model].extra != "card" && <img src={getUsrThumbUrl(id, updated_at || "")} height="180px" decoding="async" loading="lazy"
          className="m-auto" alt="..." style={{ boxShadow, borderRadius: 5, border: "1px solid gray"}} />
        }
       </div>
      <div className="card-body p-1"> 
        <p className="card-text text-center text-ellipsis">{name.includes("{{") ? name.slice(0,-7) : name}</p>
      </div>
    </div>
  );
}

function CardNew(props: { numDesigns: number, projectId: string, editHandler: (p: DesignData) => void, user: UserData }) {
  const { numDesigns, projectId, editHandler, user } = props;

  const createNew = () => {
    const email = user.email.trim().toLowerCase()
    if (!email.endsWith("@ingeniumsl.com") && !email.endsWith("@besknx.com") && numDesigns >= MAX_DESIGNS) {
      toast.error(t`You've reached the max number of designs for your account, please delete some to open some space`);
      return;
    }

    editHandler({ ...DefaultDesign, project_id: projectId });
  }

  return (
    <div className="card hover-primary text-white" onClick={handler(createNew)} title={t`New design`}
      style={{ width: 200, height: 390, margin: 10, cursor: "pointer", backgroundColor: "rgba(0,0,0,0.2)" }}>
      <div className="d-flex rounded-top w-100 p-2" style={{ height: 350 }} >
        <FaPlusCircle style={{ width: "30%", height: "auto", margin: "auto", boxShadow, borderRadius: 500 }} />
      </div>
      <div className="card-body p-1">
        <p className="card-text text-center text-ellipsis">{t`New design`}</p>
      </div>
    </div>
  );
}

function Navbar(props: any) {
  if(!isApp)
  {
    return (
      <nav className="navbar sticky-top navbar-expand navbar-dark bg-dark" style={{ boxShadow, marginBottom: isMobile() ? "50px" : "0px" }}>
        <NavbarContent {...props} />
  
        {isAdminInpersonate() && <div className="nav-item">
          <a className="nav-link small-mobile text-light" href="#adm"
            onClick={() => { inpersonateGoBack(); window.location.reload(); }} >
            <FaUndo style={{ verticalAlign: -2.5 }} />
            <span className="pl-2">{t`Back to Admin`}</span>
          </a>
        </div>}
  
        <div className="nav-item dropdown">
          <a className="nav-link dropdown-toggle small-mobile text-light" href="#drop"
            id="navbarDropdown" role="button" data-toggle="dropdown">
            <FaUser style={{ verticalAlign: -2.5 }} />
            <span className="pl-2">{props.name}</span>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            {/*<a className="dropdown-item" href="#a1" data-toggle="modal" data-target={"#accountModal"}>{t`My account`}</a>
            <div className="dropdown-divider"></div>*/}
            <a className="dropdown-item" href="#a2" onClick={props.logoutHandler}>{t`Log out`}</a>
          </div>
        </div>
      </nav>
    );

  } 
  else
  {
    appInfoSetup(
      "TEST",
      { visibility: true }, 
      { icon: "create", callback: "addDevice" }
    );

    console.log("MyComelit App");
    return(<div></div>);
  }
}

function NavbarContent(props: any) {
  const { editHandler, editorRef, editing, newProject, sort } = props;

  if (editing) {
    return <>
      <a className="navbar-brand text-light" href="#pro" title="Volver atrás"
        onClick={handler(p => editHandler(p, true))}>
        <FaAngleLeft className="mb-1 mr-1" />
        {t`Editor`}
      </a>
      <div className="navbar-nav mr-auto">
        <a className="nav-item nav-link active ml-5 text-light small-mobile" href="#link"
          onClick={handler(() => editorRef.current.saveDesign(editHandler))}>
          <FaSave style={{ verticalAlign: -2.5 }} />
          <span className="pl-2">{t`Save`}</span>
        </a>
      </div>
    </>;
  } else {
    return <>
      {!isMobile() && <a href="#pro" className="hover-opacity" style={{ height: 44, outline: "none" }}
        onClick={handler(editHandler)}>
        <CubLogo id="logo-full" height="100%" />
        <CubLogoShort id="logo-short" height="100%" />
      </a>}
      <ul className="navbar-nav m-auto">
        <li className="nav-item">
          <a className="nav-link text-light text-ellipsis small-mobile" href="#link"
            onClick={handler(newProject)}>
            <FaPlusCircle className="text-primary" style={{ fontSize: 28, verticalAlign: -8 }} />
            <span className="ml-2">{t`New project`}</span>
          </a>
        </li>
        <li>
          <div className="nav-item dropdown ml-2">
            <a className="nav-link dropdown-toggle small-mobile text-light" href="#drop"
              id="sortDropdown" role="button" data-toggle="dropdown" style={{ marginTop: 2.4 }}>
              {sort.ascending ? <FaSortAmountUp style={{ verticalAlign: -2.5 }} />
                : <FaSortAmountDown style={{ verticalAlign: -2.5 }} />}
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              {[{ label: t`Creation date`, value: ProjectSortField.created_at },
              { label: t`Last updated`, value: ProjectSortField.updated_at },
              { label: t`Status`, value: ProjectSortField.status },
              { label: t`Name`, value: ProjectSortField.name },].map(({ label, value }, i) =>
                <a key={i} className="dropdown-item" href="#c">
                  <div className="custom-control custom-radio">
                    <input type="radio" className="custom-control-input" id={`checkSort${i}`} checked={sort.field === value} onChange={e => sort.setField(value)} />
                    <label className="w-100 custom-control-label" htmlFor={`checkSort${i}`}>{label}</label>
                  </div>
                </a>
              )}

              <div className="dropdown-divider"></div>

              <a className="dropdown-item" href="#a">
                <div className="custom-control custom-radio">
                  <input type="radio" className="custom-control-input" id="checkSortAsc" checked={sort.ascending} onChange={e => sort.setAscending(true)} />
                  <label className="w-100 custom-control-label" htmlFor="checkSortAsc"> {t`Ascending`}</label>
                </div>
              </a>
              <a className="dropdown-item" href="#d">
                <div className="custom-control custom-radio">
                  <input type="radio" className="custom-control-input" id="checkSortDesc" checked={!sort.ascending} onChange={e => sort.setAscending(false)} />
                  <label className="w-100 custom-control-label" htmlFor="checkSortDesc"> {t`Descending`}</label>
                </div>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </>;
  }
}


/*
  -New project: onClick={handler(newProject)}
  -Back Editor: onClick={handler(p => editHandler(p, true))}
  -Save:        onClick={handler(() => editorRef.current.saveDesign(editHandler))}
*/