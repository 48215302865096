import React from "react";
import { t } from "ttag";
import { StylesConfig } from "react-select";
import { DesignData } from "./types";

export type Dict<V> = { [key: string]: V };

export const MAX_DESIGNS = 250;

export const MODE = process.env.REACT_APP_MODE === "bes" ? "bes" : "ing";
export const PROD = (process.env.NODE_ENV || "").startsWith("prod");

export const TEXT_SEPARATOR = "|!"; // Don't change this!

const BASE_URL = "";

const AUTH_TOKEN = "auth_token";
// This is only used to enable admins logging in as other users to edit orders in process
const ADMIN_INPERSONATE_TOKEN = "ait";
const ADMIN_INPERSONATE_VALUE = "45c0bd149";

const ADMIN_GOBACK_TOKEN = "goback_token";

export function getToken() {
  return window.sessionStorage.getItem(AUTH_TOKEN);
}

export function setToken(token?: string) {
  if (token === undefined) {
    window.sessionStorage.removeItem(AUTH_TOKEN);
    window.sessionStorage.removeItem(ADMIN_INPERSONATE_TOKEN);
    window.sessionStorage.removeItem(ADMIN_GOBACK_TOKEN);

  } else {
    window.sessionStorage.setItem(AUTH_TOKEN, token);
  }
}

export function isAdminInpersonate() {
  return window.sessionStorage.getItem(ADMIN_INPERSONATE_TOKEN) === ADMIN_INPERSONATE_VALUE;
}

export function adminInpersonate(newToken: string) {
  const oldToken = window.sessionStorage.getItem(AUTH_TOKEN)!;
  window.sessionStorage.setItem(AUTH_TOKEN, newToken);
  window.sessionStorage.setItem(ADMIN_GOBACK_TOKEN, oldToken);
  window.sessionStorage.setItem(ADMIN_INPERSONATE_TOKEN, ADMIN_INPERSONATE_VALUE);
}

export function inpersonateGoBack() {
  window.sessionStorage.setItem(AUTH_TOKEN, window.sessionStorage.getItem(ADMIN_GOBACK_TOKEN)!);
  window.sessionStorage.removeItem(ADMIN_GOBACK_TOKEN);
  window.sessionStorage.removeItem(ADMIN_INPERSONATE_TOKEN);
}

export const boxShadow = "3px 4px 8px 1px rgba(0, 0, 0, 0.2), 5px 8px 20px 3px rgba(0, 0, 0, 0.1)";
export const boxShadowBig = "10px 12px 20px 10px rgb(0, 0, 0, 0.3)";
export const filterShadow = "drop-shadow(3px 4px 6px rgba(0, 0, 0, 0.4))"

export const MOBILE_VIEW_CUTOFF = 700;

export function isMobile() {
  return window.innerWidth < MOBILE_VIEW_CUTOFF;
}

export function isIPhone() {
  return /iPhone|iPod/.test(navigator.userAgent);
}


export function isLandscapeDesign(design: DesignData) {
  const isLandscape = design.model[3] === "3" || design.model === "SR592210" || design.model === "SR592215";

  return isLandscape;
}



export const sketchColors = ['#D0021B', '#F5A623',
  '#F8E71C', '#8B572A', '#7ED321', '#417505',
  '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2',
  '#B8E986', '#000000', '#4A4A4A', '#9B9B9B']// Removed '#FFFFFF' from last

export function unfocusAll() {
  document.querySelectorAll("input").forEach(i => i.blur());
}

export function shiftTop() {
  window.scrollTo(0, 0);
  document.querySelectorAll(".scroller").forEach(s => s.scrollTop = 0);
}

export const OVERLAY = document.getElementById("loadingOverlay");
export const OVERLAY_TEXT = document.getElementById("loadingOverlayText");

export function loading(show: boolean) {
  OVERLAY?.classList.toggle("loading", show);
}

export function loadingText(text: string) {
  OVERLAY_TEXT && (OVERLAY_TEXT.innerHTML = text);
}

export const groupBy = function <T>(arr: T[], grouper: (val: T) => string) {
  const initial: { [key: string]: T[] | undefined } = {};

  return arr.reduce((prev, curr) => {
    let key = grouper(curr);
    (prev[key] = prev[key] || []).push(curr);
    return prev;
  }, initial);
}

export function randId() {
  return '_' + Math.random().toString(36).substr(2, 9);
}

function prepHeaders(auth: boolean) {
  const headers = { 'Accept': 'application/json' };

  if (auth) {
    return Object.assign(headers, { 'Authorization': "Bearer " + getToken() });
  }

  return headers;
}

export function fetchGet(url: string, auth: boolean = true) {
  const fullUrl = url.startsWith(BASE_URL) ? url : BASE_URL + url;
  return fetch(fullUrl, { headers: prepHeaders(auth) });
}

export function fetchPost(url: string, body?: string | FormData | Blob, auth: boolean = true) {
  const fullUrl = url.startsWith(BASE_URL) ? url : BASE_URL + url;

  let headers = prepHeaders(auth);

  if (!(body instanceof FormData)) {
    headers = Object.assign(headers, { 'Content-Type': "application/json" });
  }
  return fetch(fullUrl, { method: 'POST', body, headers });
}

export function fetchPostImage(url: string, body?:  Blob, auth: boolean = true) {
  const fullUrl = url.startsWith(BASE_URL) ? url : BASE_URL + url;

  let headers = prepHeaders(auth);

  return fetch(fullUrl, { method: 'POST', body, headers });
}

// Basic handler for use where we don't care about the event (ex. click handlers...)
export function handler<E extends React.SyntheticEvent>(
  handler?: (...params: any[]) => any, ...params: any[]
) {
  return (e: E) => {
    e.preventDefault();
    e.stopPropagation();
    handler?.(...params);
    return false
  }
}

// Handler for the cases where we do care about the event
export function handleEvent<E extends React.SyntheticEvent>(
  handler?: (e: E, ...params: any[]) => any, ...params: any[]
) {
  if (!handler) { return undefined; }
  return (e: E) => {
    e.preventDefault();
    e.stopPropagation();
    handler(e, ...params);
    return false;
  }
}

export const filenamify = (text: string) => {
  return text
    .replace(/[^ña-z0-9.-]/gi, '_')
    .replace(/\.{2,}/g, ".")
    .slice(0, 100);
}

export const pullQuery = (keep?: boolean) => {
  const urlParams = new URLSearchParams(window.location.search);
  if (!keep) { window.history.replaceState(null, null as any, window.location.pathname); }
  return urlParams;
}

export const getIconGroupName = (group: string) => {
  switch (group) {
    case "01":
    case "01-iluminacion":
      return t`Lighting`;

    case "02":
    case "02-cortinapersiana":
      return t`Curtains`;

    case "03":
    case "05-hotel":
      return t`Hotel`;

    case "04":
    case "03-clima":
      return t`Climate`;

    case "06":
    case "04-flechas":
      return t`Arrows`;

    case "07":
    case "08-exterior":
      return t`Exterior`;

    case "08":
    case "06-puertas":
      return t`Doors`;

    case "09":
    case "07-garage":
      return t`Garage`;

    case "10":
    case "09-media":
      return t`Media`;

    case "10-otros":
      return t`Other`;
    default: return t`Unknown`;
  }
}

export const parseJson = (text?: string | null) => {
  if (!text) return null;
  try {
    return JSON.parse(text);
  } catch (err) {
    return null;
  }
}

export const FontSelectColourStyles: StylesConfig<{ label: any; value: any, enabled?: boolean }, false> = {
  option: (styles, { isDisabled }) => {
    if (!isDisabled) return { ...styles, lineHeight: 1, };

    return {
      ...styles,
      color: '#FFFFF',
      lineHeight: 1,
      backgroundColor: '#ddd',
      fontWeight: 600,
      textAlign: 'right',
      cursor: 'not-allowed'
    };
  }
};

export const fixColor = (color?: string) => {
  if (!color) return undefined;
  const c = color.toLowerCase();
  if (color === "#fff" || color === "#ffffff" || color === "white") {
    return "#fffffa";
  }
  return c;
}

export const parseColor = (color?: string) => {
  let m = color?.match(/^#([0-9a-f]{6})$/i)?.[1];
  if (!m) return null;

  return [
    parseInt(m.substring(0, 2), 16),
    parseInt(m.substring(2, 4), 16),
    parseInt(m.substring(4, 6), 16)
  ];
}

export const getIconUrl = (path: string) => {
  // Extract version and the rest
  const match = /^v(\d+):(.*)/.exec(path);
  const version = match?.[1] ?? '0';

  // Remove version from first segment
  path = match?.[2] ? match[2] : path;

  const url = `${BASE_URL}/files/icons/v${version}/${path}`;
  return url.endsWith(".svg") ? url : `${url}.svg`;
}

export const getBgUrl = (path: string, isUser?: boolean, id?: string, updated_at: string = "") => {
  if (path.startsWith("blob:") || path.startsWith("#")) { return path; }
  if (path.startsWith(`${BASE_URL}/files/`)) { return path; }

  if (isUser) {
    return `${BASE_URL}/files/usr/bg/${id}.${path}?ver=${updated_at.replace(/[-:. ]/g, "")}`;
  } else {
    return `${BASE_URL}/files/bg/${path}`;
  }
}

export const getLogoUrl = (path: string, id?: string,  updated_at: string = "") => {
  if (path.startsWith("blob:")) { return path; }
  if (path.startsWith(`${BASE_URL}/files/`)) { return path; }

  return `${BASE_URL}/files/usr/logos/${id}.${path}?ver=${updated_at.replace(/[-:. ]/g, "")}`;
}

export const getModelUrl = (name: string, color: string) =>
  `${BASE_URL}/files/models/${MODE}/${name.toLowerCase()}-${color}.png`;

export const getBackUrl = (color: string) =>
  `${BASE_URL}/files/backs/bc-${MODE}-${color}.png`;

export const getMaskUrl = (vertical: string) =>
  `${BASE_URL}/files/masks/mask-${vertical}.png`;

export const getUsrThumbUrl = (id: string, updated_at: string) =>
  `${BASE_URL}/files/usr/thumbs/${id}.png?ver=${updated_at.replace(/[-:. ]/g, "")}`;


export const friendlyDate = (date?: string | number) => {
  if (!date) { return "unknown"; }

  const now = Date.now();
  const then = typeof date === "number" ? date : parseDateUTC(date);
  // Seconds since
  let n = Math.round((now - then) / 1000);
  const f = Math.floor;

  const minute = 60,
    hour = minute * 60,
    day = hour * 24,
    week = day * 7,
    month = day * 31,
    year = day * 365;

  if (n < 20) { return t`just now`; }
  if (n < minute) { return t`${n} seconds ago`; }
  if (n < 2 * minute) { return t`a minute ago`; }
  if (n < hour) { n = f(n / minute); return t`${n} minutes ago`; }
  if (n < hour * 2) { return t`one hour ago`; }
  if (n < day) { n = f(n / hour); return t`${n} hours ago`; }
  if (n < day * 2) { return t`yesterday`; }
  if (n <= week) { return t`last week`; }
  if (n <= week * 2) { return t`two weeks ago`; }
  if (n <= month) { return t`last month`; }
  if (n < year) { n = f(n / month); return t`${n} months ago`; }
  n = f(n / year); return t`${n} years ago`;
}

export const parseDateUTC = (date: string) => {
  // Try to convert '2019-09-19 08:43:30'
  // To '2019-09-19T08:43:30Z' (Z for UTC)
  const toUTC = Date.parse(date.replace(" ", "T") + "Z");
  if (toUTC) { return toUTC; }

  return Date.parse(date);
}

export const friendlyPassScore = (score: number) => {
  switch (score) {
    case 0: case 1: return t`Weak`;
    case 2: return t`Average`;
    case 3: return t`Good`;
    case 4: return t`Strong`;
  }
}

const LANG_KEY = "LANG";
export let available_locales: Dict<string>;
export let default_locale: string;
let localeSwitcher: (a: string) => void;
export const configureLocale = (def: string, available: Dict<string>) => {
  default_locale = def;
  available_locales = available;
}
export const configureLocaleSwitcher = (switcher: (lang: string) => void) => localeSwitcher = switcher;

export const getLocale = () => {
  const available = Object.keys(available_locales);
  // Try to get user preference, if available
  const val = window.localStorage.getItem(LANG_KEY);
  if (val && available.indexOf(val) !== -1) { return val; }

  // Otherwise try to get it from the browser
  const lang = navigator.language || (navigator as any).userLanguage as string;
  if (lang) {
    const langCode = lang.slice(0, 2).toLowerCase();
    if (available.indexOf(langCode) !== -1) { return langCode; }
  }

  // Otherwise use default
  return default_locale;
}
export const setLocale = (locale: string) => {
  window.localStorage.setItem(LANG_KEY, locale);
  localeSwitcher?.(locale);
}

export const embedSvgImages = async (svg: string) => {
  const matches = svg.match(/xlink:href="([^"]*)"/g);
  if (!matches) return svg;

  const urls = matches.map(m => m.substring(12, m.length - 1));

  const downloadImg = async (path: string) => {
    const res = await fetchGet(path);
    const blob = await res.blob();

    const data = await blobToDataURL(blob);

    return data;
  }

  const files = await Promise.all(urls.map(u => downloadImg(u)));

  files.forEach((f, i) => {
    svg = svg.replace(urls[i], f);
  });

  return svg;
}

export const isCharRTL = (c: string) => {
  // https://stackoverflow.com/a/40353238
  return /[\u0590-\u06FF]/.test(c);
}

export const blobToDataURL = (blob: Blob) => {
  return new Promise<string>((resolve, reject) => {
    var fr = new FileReader();
    fr.onerror = reject;
    fr.onload = e => resolve((fr as any).result);
    fr.readAsDataURL(blob);
  })
}
